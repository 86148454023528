<template>
	<figure>
		<InputSelect
			v-model="selectedQuestionType"
			:items="questionTypes"
			:itemText="'txt'"
			:itemValue="'component'"
		>
			<template #label> <b>Type of Question</b> </template>
		</InputSelect>

		<AdminDefaultSubheader
			v-if="selectedQuestionType !== ''"
			class="mt-16 mb-8"
			>Responses</AdminDefaultSubheader
		>
		<span v-if="responses.length === 0">(There is no respones)</span>
		<keep-alive v-else>
			<component
				:is="selectedQuestionType"
				:responses="responses"
				@updateResponses="updateResponses"
			/>
		</keep-alive>
		<section class="mt-16 d-flex justify-end">
			<v-btn
				v-if="selectedQuestionType !== ''"
				class="admin-primary-button"
				@click="addNewResponse"
				>Add Response</v-btn
			>
			<v-btn class="admin-primary-button ml-4" :disabled="true"
				>Add Response from bank</v-btn
			>
		</section>
	</figure>
</template>
<script>
import NewQuestionResponseCheckbox from "./NewQuestionResponseCheckbox.vue";
import NewQuestionResponseFreeForm from "./NewQuestionResponseFreeForm.vue";
import NewQuestionResponseMultipleChoices from "./NewQuestionResponseMultipleChoices.vue";
import NewQuestionResponseTrueFalse from "./NewQuestionResponseTrueFalse.vue";
import AdminDefaultSubheader from "@/components/AdminDefaultSubheader.vue";
import { v4 as uuidv4 } from "uuid";

export default {
	name: "NewQuestionResponse",
	components: {
		NewQuestionResponseCheckbox,
		NewQuestionResponseFreeForm,
		NewQuestionResponseMultipleChoices,
		NewQuestionResponseTrueFalse,
		AdminDefaultSubheader
	},
	props: {
		responses: {
			type: Array,
			require: true
		},
		type: {
			type: String,
			require: true
		}
	},
	computed: {
		selectedQuestionType: {
			get() {
				return this.type;
			},
			set(val) {
				this.$parent.$emit("setValue", {
					key: "type",
					value: val
				});
			}
		}
	},
	data() {
		return {
			questionTypes: [
				{
					id: 1,
					txt: "Multiple Choices",
					component: "NewQuestionResponseMultipleChoices"
				},
				{
					id: 2,
					txt: "True or False",
					component: "NewQuestionResponseTrueFalse"
				},
				{
					id: 3,
					txt: "Checkbox ",
					component: "NewQuestionResponseCheckbox"
				},
				{
					id: 4,
					txt: "Free Form",
					component: "NewQuestionResponseFreeForm"
				}
			]
		};
	},
	methods: {
		addNewResponse() {
			this.$emit("addNewResponses");
		},
		updateResponses() {
			this.$emit("setValue", {
				key: "responses",
				value: this.responses
			});
		}
	},
	updated() {
		this.updateResponses();
	}
};
</script>

<style></style>
